import axios from 'axios';


const url = '/jobs';
export default {

    index(query) {
        const params = new URLSearchParams(query);
        return axios.get(`${url}?${params.toString()}`);
    },

};
