import axios from 'axios';


const url = '/operations';

export default {
    refreshDatabase(db) {
        return axios.post(`${url}/database/refresh`, {database: db});
    }
};
