import axios from 'axios';


const url = '/documentcategories';
export default {
    create(data) {
        return axios.post(url, data);
    },

    read(id) {
        return axios.get(`${url}/${id}`);
    },

    update(data) {
        return axios.put(`${url}/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${url}/${id}`);
    },

    index(query) {
        const params = new URLSearchParams(query);
        return axios.get(`${url}?${params.toString()}`);
    },

};
