import axios from 'axios';


const url = '/diagnosiscodes';
const categoryUrl = '/diagnosiscategories';
export default {
    create(data) {
        return axios.post(url, data);
    },

    read(id) {
        return axios.get(`${url}/${id}`);
    },

    update(data) {
        return axios.put(`${url}/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${url}/${id}`);
    },

    index(query) {
        const params = new URLSearchParams(query);
        return axios.get(url, {
            params: query
        });
    },

    search(query) {
        return axios.get(url, {
            params: {
                q: query
            }
        });
    },

    treeSearch(query) {
        return axios.get(url, {
            params: {
                q: query,
                treeSearch: true
            }
        });
    },

    handleTreeChange(data) {
        return axios.post(`${url}/tree`, data);

    },

    fetchValues(request) {
        return axios.get(`${url}/values`, {
            params: request
        });

    },

    fetchTree(request) {
        return axios.get(`${url}/tree`, {
            params: request
        });
    },

    fetchChildren(id) {
        return axios.get(`${categoryUrl}/${id}/children`);
    },


};
