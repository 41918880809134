import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import {debounce, throttle} from 'lodash-es';

const pinia = createPinia().use(piniaPluginPersistedstate);
pinia.use(({options, store}) => {
    if (options.throttle) {
        // we are overriding the actions with new ones
        return Object.keys(options.throttle).reduce((throttledActions, action) => {
            throttledActions[action] = throttle(
                store[action],
                options.throttle[action]
            )
            return throttledActions
        }, {})
    }
});

pinia.use(({options, store}) => {
    if (options.debounce) {
        // we are overriding the actions with new ones
        return Object.keys(options.debounce).reduce((debouncedActions, action) => {
            debouncedActions[action] = debounce(
                store[action],
                options.debounce[action]
            )
            return debouncedActions;
        }, {})
    }
});


export default pinia;
