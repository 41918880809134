import axios from 'axios';

const url = '/customers';
const actionUrl = '/customer';

export default {
    create (data) {
        return axios.post(url, data);
    },

    read (id) {
        return axios.get(`${url}/${id}`);
    },

    update (data) {
        return axios.put(`${url}/${data.id}`, data);
    },

    delete (id) {
        return axios.delete(`${url}/${id}`);
    },

    index (query) {
        return axios.get(url, {
            params: query
        });
    },

    plans (customerId) {
        return axios.get(`${actionUrl}/plans`, {
            params: {customerId}
        });
    },

    rates (customerId) {
        return axios.get(`${actionUrl}/rates`, {
            params: {customerId}
        });
    }

};
