import api from '@/api/explanation';

const getDefaultState = () => {
    return {
        all: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {},

    actions: {
        reset({commit}) {
            commit('RESET');
        },
        index({commit}, query) {
            api.index(query).then(({data}) => {
                commit('INDEX', data);
            });
        },
        read({commit}, id) {
            api.read(id).then(({data}) => {
                commit('READ', data);
            });
        },

        create({commit}, formData) {
            return api.create(formData).then(({data}) => {
                commit('READ', data);
                return data;
            });
        },
        update({commit}, formData) {
            api.update(formData).then(({data}) => {
                commit('READ', data);
            });
        },
        delete({commit}, id) {
            api.delete(id).then(() => {
                commit('DELETE', id);
            });
        },

    },

    mutations: {
        RESET(state) {
            Object.assign(state, getDefaultState());
        },

        INDEX(state, responseData) {
            state.all = responseData;
        },

        READ(state, responseData) {

            const idx = state.all.findIndex(a => a.id == responseData.id);

            if (idx >= 0) {

                state.all.splice(idx, 1, responseData);
            } else {

                state.all.push(responseData);
            }
        },
        DELETE(state, id) {
            const idx = state.all.findIndex(a => a.id == id);
            if (!idx >= 0) {
                state.all.splice(idx, 1);
            }
        },

    }

};
