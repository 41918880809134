import api from '@/api/fieldmap';

const getDefaultState = () => {
    return {
        all: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {},

    actions: {
        reset ({commit}) {
            commit('RESET');
        },
        index ({commit}, query) {
            return api.index(query).then(({data}) => {
                return commit('INDEX', data);
            });
        },

    },

    mutations: {
        RESET (state) {
            Object.assign(state, getDefaultState());
        },

        INDEX (state, responseData) {
            state.all = responseData;
        },

    }

};
