import api from '@/api/operation';

const getDefaultState = () => {
    return {
        all: [],
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {},

    actions: {
        refreshDatabase ({}, db) {
            return api.refreshDatabase(db);
        }

    },

    mutations: {
        RESET (state) {
            Object.assign(state, getDefaultState());
        },

    }

};
