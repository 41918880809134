import axios from 'axios';


const url = '/procedurecollections';
const itemurl = '/procedurecollectionitems';
export default {
    create(data) {
        return axios.post(url, data);
    },

    read(id) {
        return axios.get(`${url}/${id}`);
    },

    update(data) {
        return axios.put(`${url}/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${url}/${id}`);
    },

    index(query) {
        const params = new URLSearchParams(query);
        return axios.get(`${url}?${params.toString()}`);
    },

    postProcedures(data) {
        return axios.post(`${itemurl}`, data);
    },

    getProcedures(procedureCollectionId) {
        return axios.get(`${itemurl}?id=${procedureCollectionId}`);
    }

};
