import user from '@/api/user';

export default {
    namespaced: true,
    state: {
        authenticated: false,
        username: '',
        name: '',
        userType: '',
        permissions: [],
        roles: [],
    },

    getters: {
        isAuthenticated(state) {
            return state.authenticated;
        },
        userHasRole: (state) => (role) => {
            if (!role) {
                return false;
            }
            return state.roles.findIndex(a => a.name.toLowerCase() == role.toLowerCase()) >= 0
                || state.roles.findIndex(a => a.name.toLowerCase() == 'super admin') >= 0;
        },
        userHasPermission: (state) => (permission) => {
            if (!permission) {
                return false;
            }
            return state.permissions.findIndex(a => a.name.toLowerCase() == permission.toLowerCase()) >= 0
                || state.roles.findIndex(a => a.name.toLowerCase() == 'super admin') >= 0;
        }
    },

    actions: {
        authenticate({
                         commit,
                         dispatch
                     }, formData) {
            return user.authenticateUser(formData).then((response) => {
                commit('AUTHENTICATE', response.data);
                dispatch('fetchUser');
                return 'SUCCESS';
            }).catch((e) => {
                return e.response.data.message;
            });
        },
        create({dispatch}, formData) {
            user.registerUser(formData).then(({data}) => {
                dispatch('authenticate');
            });
        },
        deauthenticate({commit}) {
            user.deauthenticateUser();
            commit('DEAUTHENTICATE');
        },
        fetchUser({commit}) {
            user.fetchUser().then(({data}) => {
                commit('FETCH', data);
            });
        }
    },

    mutations: {
        AUTHENTICATE(state, data) {
            state.authenticated = true;
        },
        DEAUTHENTICATE(state) {
            state.username = '';
            state.authenticated = false;
        },
        FETCH(state, data) {
            state.username = data.email;
            state.name = data.name;
            state.userType = data.userType;
            state.permissions = data.permissions;
            state.roles = data.roles;
        }
    }

};
