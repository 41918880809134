import axios from 'axios';


const url = '/triggers';
export default {
    create(data) {
        return axios.post(url, data);
    },

    read(id) {
        return axios.get(`${url}/${id}`);
    },

    update(data) {
        return axios.put(`${url}/${data.id}`, data);
    },

    delete(id) {
        return axios.delete(`${url}/${id}`);
    },

    index(query) {
        const params = new URLSearchParams(query);
        return axios.get(`${url}?${params.toString()}`);
    },

    trigger(triggerId, data) {
        if (data.file) {
            const formData = new FormData();
            formData.append('file', data.file);
            Object.keys(data).forEach((a) => {
                if (a == 'file') {
                    return;
                }
                formData.append(a, data[a]);
            });

            return axios.post(`/trigger/${triggerId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }

        return axios.post(`/trigger/${triggerId}`, data);
    }
};
