import axios from 'axios';

const url = '/dependents';
export default {
    create (data) {
        return axios.post(url, data);
    },

    read (id) {
        return axios.get(`${url}/${id}`);
    },

    update (data) {
        return axios.put(`${url}/${data.id}`, data);
    },

    delete (id) {
        return axios.delete(`${url}/${id}`);
    },

    index (query) {
        console.log('api query', query);
        return axios.get(`${url}`, {
            params: query
        });
    },

};
