import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from '@/router';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = '/api/';
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error.response && error.response.status === 401) {
        router.push('/logout');
    }
    return Promise.reject(error);
});

Vue.use(VueAxios, axios);


