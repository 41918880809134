import api from '@/api/procedurecollection';

const getDefaultState = () => {
    return {
        all: [],

    };
};

function findItem(id, arr) {
    return arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === id) return item;
        if (item.children) return findItem(id, item.children);
    }, null);
}

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        getById(state) {
            return (id) => {
                return state.all.find(a => Number(a.id) === Number(id));
            };
        }
    },

    actions: {
        reset({commit}) {
            commit('RESET');
        },

        index({commit}, query) {
            api.index(query).then(({data}) => {
                commit('INDEX', data);
            });
        },

        read({commit}, id) {
            api.read(id).then(({data}) => {
                commit('READ', data);
            });
        },

        create({commit}, formData) {
            return api.create(formData).then(({data}) => {
                commit('READ', data);
                return data;
            });
        },

        update({commit}, formData) {
            api.update(formData).then(({data}) => {
                commit('READ', data);
            });
        },

        delete({commit}, id) {
            api.delete(id).then(() => {
                commit('DELETE', id);
            });
        },

        saveProcedures({commit}, formData) {
            return api.postProcedures(formData);
        },

        getProcedures({commit}, procedureCollectionId) {
            return api.getProcedures(procedureCollectionId).then(({data}) => {
                return data;
            });

        }

    },

    mutations: {
        RESET(state) {
            Object.assign(state, getDefaultState());
        },

        INDEX(state, responseData) {
            state.all = responseData;
        },


        READ(state, responseData) {

            const idx = state.all.findIndex(a => a.id == responseData.id);

            if (idx >= 0) {
                state.all.splice(idx, 1, responseData);
            } else {
                state.all.push(responseData);
            }
        },
        DELETE(state, id) {
            const idx = state.all.findIndex(a => a.id == id);
            if (!idx >= 0) {
                state.all.splice(idx, 1);
            }
        },

    }

};
