<template>
  <v-app>
    <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndDown" v-model="showLeftNavigation" app>
      <mobile-menu></mobile-menu>
    </v-navigation-drawer>

    <v-app-bar
        app
        extended
    >
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click.stop="showLeftNavigation = !showLeftNavigation"
      ></v-app-bar-nav-icon>
      <template v-slot:img="{ props }">
        <v-img
            gradient="to top right, rgba(255,255,255,1), rgba(255,255,255,.8)"
            v-bind="props"
        ></v-img>
      </template>
      <v-container fill-height fluid>
        <v-avatar class='mr-5'>
          <img src="/img/cerberus.svg" alt="" height="100%" width="100%">
        </v-avatar>

        <v-toolbar-title class="mr-5">
          Cerberus
        </v-toolbar-title>


        <v-spacer></v-spacer>
        <template v-if="isAuthenticated">
          <v-btn icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

          <v-btn icon @click="logout">
            <v-icon>mdi-logout</v-icon>
          </v-btn>

        </template>

        <template v-if="!isAuthenticated">
          <v-btn text @click="$router.push('/login').catch(()=>{})">
            Login
          </v-btn>

        </template>


      </v-container>
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:extension>
        <v-container fluid>
          <template v-if="isAuthenticated">
            <menu-component></menu-component>
          </template>
        </v-container>
      </template>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main id="mainmain">
      <!-- Provides the application the proper gutter -->
      <v-container fluid style="height:100%;">
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <v-container fluid>
        <v-row>
          <v-spacer></v-spacer>
          <v-icon small>mdi-copyright</v-icon>
          {{ year }} HHT
          &nbsp;

          <v-icon>mdi-slash-forward</v-icon>
          &nbsp;

          <v-icon small left>mdi-responsive</v-icon>
          {{ $vuetify.breakpoint.name }}
          &nbsp;

          <v-icon>mdi-slash-forward</v-icon>
          &nbsp;

          <v-icon small left>mdi-pound-box</v-icon>
          {{ version }}
        </v-row>
      </v-container>
    </v-footer>
  </v-app>


</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import MenuComponent from '@/components/Menu';
  import version from '@/version.js';
  import MobileMenu from '@/components/MobileMenu';

  export default {
    name: 'App',
    components: {
      MenuComponent,
      MobileMenu
    },
    props: {},
    data () {
      return {
        activeTab: 1,

        showLeftNavigation: false
      };
    },
    computed: {
      ...mapGetters('user', {
        isAuthenticated: 'isAuthenticated'
      }),

      year () {
        return new Date().getFullYear();
      },
      version () {
        return version;
      }
    },
    methods: {
      ...mapActions('user', {
        deauthenticate: 'deauthenticate'
      }),
      logout () {
        this.deauthenticate().then(() => {
          this.$router.push({name: 'Login'});
        });
      }
    },
    mounted () {
      this.activateMultipleDraggableDialogs();
    },

  };
</script>

<style scoped>

</style>
