import axios from 'axios';

const url = '/fieldmaps';
export default {

    index (query) {
        return axios.get(`${url}/${query}`);
    },

};
