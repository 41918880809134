<template>
  <div>
    <v-list>
      <template v-for="(menuItem, idx) in menuItems">
        <template v-if="menuItem.link">
          <v-list-item
              :key="idx"
              :to="menuItem.link"
          >
            <v-list-item-icon>
              <v-icon
                  v-if="menuItem.icon"
                  color="primary"
                  left
                  v-html="menuItem.icon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
          </v-list-item>
        </template>

        <template v-if="menuItem.children && menuItem.children.length > 0">

          <v-list-group>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="primary" left>{{ menuItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </template>

            <v-list-item
                v-for="(subMenuItem, subMenuItemIndex) in menuItem.children "
                :key="subMenuItemIndex"
                :to="subMenuItem.link"
            >
              <v-list-item-icon>
                <v-icon
                    color="primary"
                    left
                >{{ subMenuItem.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ subMenuItem.title }}</v-list-item-title>
            </v-list-item>

          </v-list-group>

        </template>
      </template>
    </v-list>


  </div>

</template>

<script>
  import menuItems from '@/menu';

  export default {
    name: 'MobileMenu',
    components: {},
    props: {},
    data () {
      return {};
    },
    computed: {},
    methods: {},
    created () {
      this.menuItems = menuItems;
    },
    mounted () {
    },
  };
</script>

<style scoped>

</style>
