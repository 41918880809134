<template>
    <div class="lsvs-menu">
        <template v-for="(menuItem, idx) in menuItems">
            <template
                v-if="!menuItem.hasOwnProperty( 'role') || userHasRole(menuItem.role)  || userHasRole(menuItem.permission)">
                <template v-if="menuItem.link && !menuItem.children.length">
                    <v-btn
                        :key="idx"
                        :to="menuItem.link"
                        color="primary"
                        text
                        small
                    >
                        <v-icon
                            v-if="menuItem.icon"
                            color="primary"
                            left
                            v-html="menuItem.icon"
                            small
                        ></v-icon>
                        {{ menuItem.title }}
                    </v-btn>
                </template>
            </template>
            <template v-if="menuItem.children && menuItem.children.length > 0">
                <template
                    v-if="!menuItem.hasOwnProperty( 'role') || userHasRole(menuItem.role) || userHasRole(menuItem.permission)">
                    <v-menu
                        bottom
                        offset-y
                        open-on-hover
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                :key="idx"
                                color="primary"
                                text
                                v-on="on"
                                :to="menuItem.link"
                            >
                                <v-icon
                                    v-if="menuItem.icon"
                                    color="primary"
                                    left
                                    v-html="menuItem.icon"
                                    small
                                ></v-icon>
                                {{ menuItem.title }}
                            </v-btn>

                        </template>

                        <v-list dense>
                            <v-list-item
                                v-for="(subMenuItem, subMenuItemIndex) in menuItem.children "
                                :key="subMenuItemIndex"
                                :to="subMenuItem.link"
                                v-if="(!Object.hasOwnProperty.call(subMenuItem, 'role') && !Object.hasOwnProperty.call(subMenuItem, 'permission')) || Object.hasOwnProperty.call(subMenuItem, 'role') && userHasRole(subMenuItem.role) || Object.hasOwnProperty.call(subMenuItem, 'permission') && userHasPermission(subMenuItem.permission)"
                            >

                                <v-list-item-title>
                                    <v-icon
                                        color="primary"
                                        v-html="subMenuItem.icon"
                                        left small
                                    ></v-icon>
                                    {{ subMenuItem.title }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </template>
        </template>
    </div>

</template>

<script>
import menuItems from '@/menu';
import {mapGetters} from 'vuex';

export default {
    name: 'Menu',
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters('user', [
            'userHasRole',
            'userHasPermission'
        ]),
    },
    methods: {},
    created() {
        this.menuItems = menuItems;
    },
    mounted() {
    },
};
</script>

<style scoped>

</style>
