import axios from 'axios';

const url = '/dashboard';
export default {

    index (query) {
        return axios.get(`${url}`, {
            params: query
        });
    },

};
